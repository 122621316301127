import { Profile } from "./profile";
import { Business } from "./business";

export class User{
    public id:number;
    public name:string;
    public email: string;
    public password:string;
    public businessid:number;
    public active: boolean;
    public image: string;
    public profileid: any;
    public profile: Profile;
    public business: Business;

    constructor(){
       this.profileid = '';
    }
}