import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {UserService} from './user.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class IdentityGuard implements CanActivate{
    constructor(
        private _router: Router,
        private _userService: UserService,
        public _toastrService: ToastrService
    ){}

    canActivate(){
        const identity = this._userService.getIdentity();

        if(identity){
            return true;
        }else{
            this._toastrService.error('El usuario no está identificado','Error!');
            this._router.navigate(['/login']);
            return false;
        }

    }
}