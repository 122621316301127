import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'sample',
    loadChildren: () => import('../../components/sample/sample.module').then(m => m.SampleModule)
  },
  
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule)
  },

  {
    path: 'error',
    loadChildren: () => import('../../components/error/error.module').then(m => m.ErrorModule)
  },
  
  {
    path: 'document',
    loadChildren: () => import('../../components/document/document.module').then(m => m.DocumentModule)
  },
  
  {
    path: 'loan',
    loadChildren: () => import('../../components/loan/loan.module').then(m => m.LoanModule)
  },
  {
    path: 'user',
    loadChildren: () => import('../../components/user/user.module').then(m => m.UserModule)
  },
  
  {
    path: 'business',
    loadChildren: () => import('../../components/business/business.module').then(m => m.BusinessModule)
  },

  {
    path: 'visit',
    loadChildren: () => import('../../components/visit/visit.module').then(m => m.VisitModule)
  },

  {
    path: 'ticket',
    loadChildren: () => import('../../components/ticket/ticket.module').then(m => m.TicketModule)
  },

  {
    path: 'profile',
    loadChildren: () => import('../../components/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'report',
    loadChildren: () => import('../../components/report/report.module').then(m => m.ReportModule)
  },

  {
    path: 'nofount',
    loadChildren: () => import('../../components/error/error.module').then(m => m.ErrorModule)
  }

];