import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import { global } from './global';

@Injectable()
export class UserService {
    public url: string;
    public identity: any;
    public token: string;
    constructor(
        public _http: HttpClient
    ){
        this.url = global.url;
    }


    register(token, user): Observable<any>{

        const json = JSON.stringify(user);
        const params = 'json='+json;

        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.post(this.url+'user', params, { headers: headers });
    }

    signup(user, gettoken = null): Observable<any>{
        if(gettoken != null){
            user.token = 'true';

        }
        let json = JSON.stringify(user);
        let params = 'json='+json;
        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
        return this._http.post(this.url+'login', params, { headers: headers });

    }
    getIdentity(){
        let identity = JSON.parse(localStorage.getItem('identity'));
        if(identity && identity !== 'undefined'){
            this.identity = identity;
        }else{
            this.identity = null;
        }
        return this.identity;
    }
    getToken(){
        let token = localStorage.getItem('token');
        if(token && token !== 'undefined'){
            this.token = token;

        }else {
            this.token = null;
        }
        return this.token;

    }
    search(token,search = '', all = false): Observable<any>{
        let url; 
        if(all){
            url = this.url+'user-searchall/';
        }else{
            url = this.url+'user-search/';
        }
        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.get(url+search, { headers: headers });
    }



    show(token,id, all = false): Observable<any>{
        let url; 
        if(all){
            url = this.url+'showall/';
        }else{
            url = this.url+'user/';
        }
        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.get(url+id, { headers: headers });
    }

    delete(token,id): Observable<any>{
        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.delete(this.url+'user/'+id, { headers: headers });
    }

    changepassword(token,id, password): Observable<any>{
        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.get(this.url+'user-password/'+id+'/'+password, { headers: headers });
    }

    update(token,user, all = false): Observable<any>{

        const json = JSON.stringify(user);
        const params = 'json='+json;

        let url; 
        if(all){
            url = this.url+'updateall/';
        }else{
            url = this.url+'user/';
        }

        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.put(url+user.id, params, { headers: headers });
    }

    checklogin(token): Observable<any>{

        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.get(this.url+'user-checklogin/', { headers: headers });
    }
}