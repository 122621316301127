import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { VisitService } from 'src/app/services/visit.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Visit } from 'src/app/models/visit';
// import { threadId } from 'worker_threads';
import { global } from 'src/app/services/global';
import { Business } from 'src/app/models/business';
import {Location} from '@angular/common';
import { User } from 'src/app/models/user';
import { BusinessService } from 'src/app/services/business.service';

@Component({
  selector: 'app-visitprint',
  templateUrl: './visitprint.component.html',
  styleUrls: ['./visitprint.component.scss'],
  providers: [UserService, VisitService,BusinessService]
})
export class VisitprintComponent implements OnInit {
  private token: string;
  public visit: Visit;
  public loading: boolean;
  public url;
  public business: Business;
  public colspan: number;
  public cbstatus: any;
  public loadingstatus: boolean;
  public status: string;
  public codigo: string;
  public user: User;
  public businessmaster: Business;

  constructor(public _userService: UserService, public _visitService: VisitService, public _toastrService: ToastrService,
    public _router: Router, public _route: ActivatedRoute,private _location: Location, private _businessService: BusinessService) {
      this.url = global.url;
      this.token = _userService.getToken();
      this.visit = new Visit();
      this.colspan = 6;
      this.cbstatus = [ {status: 'N_CONFIRMED',text : 'No confirmado'},{status: 'CONFIRMED', text: 'Confirmar'}];
      this.user = _userService.getIdentity();
      this.checkprofile();
      this.showmaster();
      this.show();
      
      
     }

  ngOnInit(): void {
  }

  checkprofile(){
    if( this.user.profileid === 2  || this.user.profileid === 1 ){
        

    }else{
      this._toastrService.error('No cuenta con los permisos suficientes', 'Error!');
        this._router.navigate(['nofount/index']);
    }
  }

  showmaster(){
   
    this._businessService.showmaster(this.token).subscribe(
      response=>{
          console.log(response);
                     
          this.businessmaster = response.business;

      },error=>{
        // console.log(error.error.errors);
        // console.log(error.error.message);
        let msj = error.error.message;
        let msjerror = '';
        if( error.error !== undefined){
          if(error.error.status !== 'logout'){
            const json = error.error.errors;

            Object.keys(json).forEach((element,key) =>{
                // console.log(json[element]);
                msjerror += json[element];

            });
            // console.log(msj);
          }else{
            msj += error.error.message;
            this._router.navigate(['/logout/1']);
          }

        }
         this._toastrService.error(msjerror, msj+'!');
        //  this._router.navigate(['/logout/1']);
      });


}

  show(){
    //this.loading = true;
    this._route.params.subscribe(
      params=>{
        const id = params.id;

        this._visitService.show(this.token, id).subscribe(
          response=>{
            console.log(response);
                
                this.visit = response.visit;
                this.business = response.visit.business;

                this.codigo = this.visit.id.toString();

                this.codigo = this.codigo.padStart(5, '0');

                this.loading = false;
                
          },error=>{
            // console.log(error.error.errors);
            // console.log(error.error.message);
            let msj = error.error.message;
            let msjerror = '';
            if( error.error !== undefined){
              if(error.error.status !== 'logout'){
                const json = error.error.errors;

                Object.keys(json).forEach((element,key) =>{
                    // console.log(json[element]);
                    msjerror += json[element];

                });
                // console.log(msj);
              }else{
                msj += error.error.message;
                this._router.navigate(['/logout/1']);
              }

            }
             this._toastrService.error(msjerror, msj+'!');
            //  this._router.navigate(['/logout/1']);
          });
      });

    }

    printPage() {
      var css = '@page { size: landscape; }',
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

      style.type = 'text/css';
      style.media = 'print';

      
      style.appendChild(document.createTextNode(css));
   
      head.appendChild(style);
      window.print();
    }



}
