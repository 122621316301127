
// export class ConfigDB {
//  	static data ={"settings":{"layout_type":"ltr","sidebar":{"type":"default","body_type":"default"},"sidebar_setting":"default-sidebar","sidebar_backround":"dark-sidebar"},"color":{"layout_version":"light","color":"color-1","primary_color":"#4466f2","secondary_color":"#1ea6ec","mix_layout":"default"},"router_animation":"fadeIn"}
// }

// export class ConfigDB {
// 	static data = {
// 	settings: {
// 	layout_type: 'ltr',
// 	sidebar: {
// 	   type: 'compact-wrapper',
// 	   body_type: 'sidebar-icon'
// 	},
// 	sidebar_setting: 'iconcolor-sidebar',
// 	sidebar_backround: 'color2-sidebar'
//  },
//  color: {
// 	layout_version: 'light',
// 	color: 'color-2',
// 	primary_color: '#0288d1',
// 	secondary_color: '#26c6da',
// 	mix_layout: 'default'
//  },
//  router_animation: 'fadeIn'
//  }
//  }

export class ConfigDB {
	static data = {
	settings: {
	layout_type: 'ltr',
	sidebar: {
	   type: 'default',
	   body_type: 'default'
	},
	sidebar_setting: 'iconcolor-sidebar',
	sidebar_backround: 'dark-sidebar'
 },
 color: {
	layout_version: '',
	color: '',
	primary_color: '',
	secondary_color: '',
	mix_layout: 'dark-body-only'
 },
 router_animation: 'fadeIn'
 }
 }


