import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './components/login/login.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { IdentityGuard } from './services/identity.guard';
import { UserService } from './services/user.service';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { FileUploadModule } from 'ng2-file-upload';
import { Ng2Rut } from 'ng2-rut';

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { VisitprintComponent } from './components/visitprint/visitprint.component';

import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
// import { StatusagencyComponent } from './components/report/statusagency/statusagency.component';


// import { ProfilelistComponent } from './components/profile/profilelist/profilelist.component'; // <- import PdfViewerModule

// import { TicketviewComponent } from './components/ticket/ticketview/ticketview.component';
// import { TypevehicleeditComponent } from './components/typevehicle/typevehicleedit/typevehicleedit.component';
// import { TypevehiclelistComponent } from './components/typevehicle/typevehiclelist/typevehiclelist.component';
// import { TypevehicleregisterComponent } from './components/typevehicle/typevehicleregister/typevehicleregister.component';
// import { VehicleeditComponent } from './components/vehicle/vehicleedit/vehicleedit.component';
// import { VehiclelistComponent } from './components/vehicle/vehiclelist/vehiclelist.component';
// import { VehicleregisterComponent } from './components/vehicle/vehicleregister/vehicleregister.component';
// import { VisiteditComponent } from './components/visit/visitedit/visitedit.component';
// import { VisitlistComponent } from './components/visit/visitlist/visitlist.component';
// import { VisitregisterComponent } from './components/visit/visitregister/visitregister.component';
// import { TransferregisterComponent } from './components/transfer/transferregister/transferregister.component';

// import { AngularFileUploaderModule } from 'angular-file-uploader';
// import { AngularFileUploaderModule } from 'angular-file-uploader';
// import { BusinesseditComponent } from './components/business/businessedit/businessedit.component';
// import { KardexbyproductComponent } from './components/kardex/kardexbyproduct/kardexbyproduct.component';
// import { DragulaModule } from 'ng2-dragula';
// import { UserlistComponent } from './components/user/userlist/userlist.component';
// import { UserregisterComponent } from './components/user/userregister/userregister.component';
// import { UsereditComponent } from './components/user/useredit/useredit.component';
// import { UserprofileComponent } from './components/user/userprofile/userprofile.component';
// import { LoanregisterComponent } from './components/loan/loanregister/loanregister.component';
// import { CollectregisterComponent } from './components/collect/collectregister/collectregister.component';
// import { DocumentlistComponent } from './components/document/documentlist/documentlist.component';
// import { FeatherIconComponent } from './components/icons/feather-icon/feather-icon.component';
// import { UnideditComponent } from './components/unit/unidedit/unidedit.component';
// import { UnidregisterComponent } from './components/unit/unidregister/unidregister.component';
// import { UnidlistComponent } from './components/unit/unidlist/unidlist.component';
// import { NofountComponent } from './components/error/nofount/nofount.component';
// import { PersonlistComponent } from './components/person/personlist/personlist.component';
// import { PersonregisterComponent } from './components/person/personregister/personregister.component';
// import { PersoneditComponent } from './components/person/personedit/personedit.component';
// import { WarehouselistComponent } from './components/warehouse/warehouselist/warehouselist.component';
// import { WarehouselistComponent } from './components/warehouseconfig/warehouselist/warehouselist.component';
// import { ProductregisterComponent } from './components/product/productregister/productregister.component';
// import { ProductlistComponent } from './componentes/product/productlist/productlist.component';
// import { Ng2CompleterModule } from "ng2-completer";
// import { Ng2SmartTableModule } from 'ng2-smart-table';
// import { DashboardshowComponent } from './components/dashboard/dashboardshow/dashboardshow.component';

// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    VisitprintComponent,
    // StatusagencyComponent,
    // ProfilelistComponent,
    // TicketviewComponent,
    // TypevehicleeditComponent,
    // TypevehiclelistComponent,
    // TypevehicleregisterComponent,
    // VehicleeditComponent,
    // VehiclelistComponent,
    // VehicleregisterComponent,
    // VisiteditComponent,
    // VisitlistComponent,
    // VisitregisterComponent

    // TransferregisterComponent
    // BusinesseditComponent,
    // KardexbyproductComponent,
    // UserlistComponent,
    // UserregisterComponent,
    // UsereditComponent,
    // UserprofileComponent
    // LoanregisterComponent,
    // CollectregisterComponent
    // DocumentlistComponent,
    // FeatherIconComponent,
    // UnideditComponent,
    // UnidregisterComponent,
    // UnidlistComponent,
    // NofountComponent,
    // PersonlistComponent,
    // PersonregisterComponent,
    // PersoneditComponent,
    // WarehouselistComponent,
    // WarehouselistComponent,
    // ProductregisterComponent,
    // ProductlistComponent
    // DashboardshowComponent,
    // CategoryregisterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    FileUploadModule,
    Ng2Rut,
    NgxMaskModule.forRoot(),
    PdfViewerModule,
    MatInputModule,
    MatFormFieldModule
    // AngularFileUploaderModule
    // AngularFileUploaderModule
    // DragulaModule
    // Ng2CompleterModule,
    // Ng2SmartTableModule
    // NgbModule
  ],
  providers: [IdentityGuard, UserService,{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
