import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [UserService]
})
export class LoginComponent implements OnInit {
  public user: User;
  private token: string;
  private identity: any;
  public loading: boolean;


  constructor(
    private _userService: UserService,
    private _router: Router,
    private _route: ActivatedRoute,
    public _toastrService: ToastrService
   
  ) {
      this.user = new User();
      this.loading = false;
      
   }

  ngOnInit(): void {
    this.logout();  //se ejecuta si recibe :sure por el url cierra sesión
    this.checklogin();
  }

  checklogin(){
    const identity = localStorage.getItem('identity');
    // console.log('identity');
    // console.log(identity);
    if(identity){
      this._router.navigate(['dashboard/inicio']);
      // console.log('dentro del if');
      // console.log(identity);
    }
  }

  onSubmit(form){
    this.loading = true;
    this._userService.signup(this.user).subscribe(
      response=>{
          if(response.status !== 'error'){
            this.token = response;

            this._userService.signup(this.user,true).subscribe(
              respuesta=>{
                this.identity = respuesta;

                // console.log(this.token);
                //  console.log(this.identity);
                // persistir datos del user identificado
                localStorage.setItem('token', this.token);
                localStorage.setItem('identity', JSON.stringify(this.identity));

                // this._warehouseconfig.index(this.token).subscribe( resp=>{
                //   // console.log(response);
                //   let wconfig = false;
                //   resp.warehouseconfigs.forEach(element => {
                //     if(element.default)
                //       wconfig=true;
                //   });

                //   if(wconfig){
                //     this._router.navigate(['dashboard/inicio']);
                //   }else {
                //     this._router.navigate(['warehouseconfig/config']);
                //   }


                // }, error=>{

                //     const msj = error.error.message;
                //     let msjerror = '';
                //     if( error.error !== undefined){
                //         const json = error.error.errors;
  
                //         Object.keys(json).forEach((element,key) =>{
                //             // console.log(json[element]);
                //             msjerror += json[element];
  
                //         });
                //         // console.log(msj);
  
  
                //     }
                //      this._toastrService.error(msjerror, msj+'!');

                //    this.loading = false;
                // });
                if(this.identity.profileid === 4 ){
                  
                  // this._router.navigate(['document/register']);
                  this._router.navigate(['dashboard/inicio']);
                }
                if(this.identity.profileid === 1){
                  this._router.navigate(['ticket/index']);
                }
                if(this.identity.profileid === 3){
                  this._router.navigate(['dashboard/inicio']);
                }
                if(this.identity.profileid === 2){
                  this._router.navigate(['visit/index']);
                }


                
              },
              error =>{
                const msj = error.error.message;
                    let msjerror = '';
                    if( error.error !== undefined){
                        const json = error.error.errors;
  
                        Object.keys(json).forEach((element,key) =>{
                            // console.log(json[element]);
                            msjerror += json[element];
  
                        });
                        // console.log(msj);
  
  
                    }
                     this._toastrService.error(msjerror, msj+'!');
              }
            );

          }else {
              console.log(response);
              this._toastrService.error(response.message, 'Error!');
              this.loading = false;
          }
      },
      error =>{
        const msj = error.error.message;
        let msjerror = '';
        if( error.error !== undefined){
            const json = error.error.errors;

            Object.keys(json).forEach((element,key) =>{
                // console.log(json[element]);
                msjerror += json[element];

            });
            // console.log(msj);


        }
          this._toastrService.error(msjerror, msj+'!');
        this.loading = false;
      }
    );
  }

  logout(){
    this._route.params.subscribe( params => {
        let logout= +params['sure'];

        if(logout === 1){

          localStorage.removeItem('identity');
          localStorage.removeItem('token');

          this.identity = null;
          this.token = null;

          this._router.navigate(['login']);
        }
    });
  }
}
