<div class="page-wrapper">
    <div class="auth-bg">
        <div class="authentication-box">
            <!-- <div class="text-center"><img src="assets/images/logo/110-48.png" alt=""></div> -->
            <div class="card mt-4" >
                <div class="card-body">
                    <div class="text-center">
                        <img src="assets/images/logo/110-48.png" alt="" class="mb-5">
                        <h4>INICIAR SESIÓN</h4>
                        <h6>Ingrese su usuario y contraseña </h6>
                    </div>
                    <form class="theme-form" #LoginFom="ngForm" (ngSubmit)="onSubmit(LoginFom)">
                        <div class="form-group">
                            <label class="col-form-label pt-0">Email</label>
                            <input class="form-control" type="email" required="" name="email" #email="ngModel" [(ngModel)]="user.email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                            <div class="text text-danger mt-1" *ngIf="email.touched && email.invalid">
                                Email no es valido
                            </div>

                        </div>
                        <div class="form-group">
                            <label class="col-form-label">Password</label>
                            <input class="form-control" type="password" required="" name="password" #password="ngModel" [(ngModel)]="user.password">
                            <div class="text text-danger mt-1" *ngIf="password.touched && password.invalid">
                                Password invalido
                            </div>
                        </div>

                        <div class="form-group form-row mt-3 mb-0">
                            <button class="btn btn-primary btn-block btn-lg" type="submit" [disabled]="LoginFom.invalid || loading"><span><i class="fa fa-refresh fa-spin" *ngIf="loading"></i>&nbsp; Iniciar sesión</span>
							</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>