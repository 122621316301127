import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import { global } from './global';


@Injectable()
export class VisitService {
    public url: string;
    constructor(
        public _http: HttpClient
    ){
        this.url = global.url;
    }

  

    register(token,visit): Observable<any>{

        const json = encodeURIComponent(JSON.stringify(visit));
        const params = 'json='+json;

        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.post(this.url+'visit', params, { headers: headers });
    }

    index(token): Observable<any>{

        const params = '';

        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.get(this.url+'visit/',  { headers: headers });
    }
    show(token, id): Observable<any>{
        const params = '';

        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.get(this.url+'visit/'+id ,  { headers: headers });

    }

    update(token,visit): Observable<any>{

        const json = JSON.stringify(visit);
        const params = 'json='+json;

        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.put(this.url+'visit/'+visit.id, params, { headers: headers });
    }

    search(token, search = 'null', to, from, status, all, trash): Observable<any>{

        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded')
        .set('Authorization',token);
        var url;
        if(all){
            url = this.url+'visit-searchall/';
        }else{
            url = this.url+'visit-search/';
        }
        
        return this._http.get(url+search+'/'+to+'/'+from+'/'+status+ '/'+ trash, { headers: headers });
    }

   

    pdf(token, id): Observable<any>{

        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded')
        .set('Authorization',token)
      
        return this._http.get(this.url+'visit-pdf/'+id, { headers: headers,responseType: 'blob'  }); //
    }

    changestatus(token, id, status): Observable<any>{
        const params = '';
        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.get(this.url+'visit-status/'+id+'/'+status ,  { headers: headers });

    }

    delete(token, id): Observable<any>{ 
        const params = '';

        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.delete(this.url+'visit/'+id ,  { headers: headers });

    }

    restore(token, id): Observable<any>{ 
        const params = '';

        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.get(this.url+'visit-restore/'+id ,  { headers: headers });

    }

}