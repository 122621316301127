// import { Person } from "./person";
import { Detailvisit } from "./detailvisit";

export class Visit{

    public id: number;
    public businessid: number;
    public date: Date;
    public rutdriver: string;
    public namedriver: string;
    public rutguidetour: string;
    public nameguidetour: string;
    public telephoneguidetour: string;
    
    public platevehicle: string;
    public observation: string;
    public namebusiness: string;
    public details: Detailvisit[] = [];
    public status: string;
    public code: string;
    constructor(){}
}