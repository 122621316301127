<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row ">
        <div class="col-sm-12">

            <div class="card">
              
                <!-- <div class="card-header">
                    <div class="row">
                        <div class="col-md-10">
                            <button (click)="printPage()" class="btn btn-primary"><i class="fa fa-print" aria-hidden="true"></i> Imprimir</button>
                        </div>
                      
                    </div>

                </div> -->


                <div class="alert alert-primary text-center" *ngIf="loading"><i class="fa fa-refresh fa-spin" style="font-size: 30px;"></i></div>
                <div class="card-body" *ngIf="!loading">

                    <div class="row">
                        <div class="col-md-12 mb-3 text-center">
                            <button (click)="printPage()" class="btn btn-primary"><i class="fa fa-print" aria-hidden="true"></i> Imprimir</button>
                        </div>
                      
                    </div>



                    <div class="clearfix encabezado">
                        <div id="logo" class="row mb-4">
                            <div class="col-lg-3 col-sm-3">
                                <img [src]="url + 'business/image/'+business.logo" alt="" width="190" height="130">
                            </div>

                            <div id="" class="business clearfix col-lg-7 col-sm-7 text-center">
                                <h6>{{ businessmaster.name }}</h6>
                                <p>FORMULARIO DE REGISTRO COVID-19, DECLARACIÓN DE SALUD DE AGENCIAS DE VIAJES, TOURS OPERADORES Y PARTICULARES</p>

                            </div>

                            <div id="" class="business clearfix col-lg-2 col-sm-2 text-center">
                                <h6><strong>Folio</strong></h6>
                                <h3><strong>{{ codigo }}</strong></h3>
                            </div>
                           
                        </div>


                        <div id="company" class="clearfix">
                            <div><span>NOMBRE DEL CONDUCTOR DEL VEHÍCULO: </span> {{ visit.namedriver }}</div>
                            <div><span>RUT.: </span> {{ visit.rutdriver }}</div>
                            

                            <div><span>PATENTE DEL VEHÍCULO: </span> {{ visit.platevehicle }}</div>
                            <div><span>NOMBRE DEL GUÍA (AGENCIA DE VIAJE O TOUR OPERADOR): </span> {{ visit.nameguidetour }}</div>
                            <div><span>RUT.: </span> {{ visit.rutguidetour }}</div>

                            <div><span>AGENCIA O TOUR OPERADOR: </span> {{ visit.namebusiness }}</div>
                            <div><span>FECHA: </span> {{ visit.date | date: 'd/MM/yyyy'  }}</div>
                            <div><span>FECHA DE CREACIÓN: </span> {{ visit.created_at | date: 'd/MM/yyyy'  }}</div>
                            <div><span>ESTADO: </span> <label class="label label-info" *ngIf="visit.status === 'register'">Registrado</label>
                                <label class="label label-danger" *ngIf="visit.status === 'N_CONFIRMED'">No Confirmado</label>
                                <label class="label label-success" *ngIf="visit.status === 'CONFIRMED'">Confirmado</label></div>

                        </div>
                        <div id="project">
                            <img [src]="url + 'business/image/'+businessmaster.logo" alt="" width="190" height="130">

                        </div>
                    </div>
                    <main>
                        <div class="table-responsive-sm">
                            <table >
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">N°</th>
                                        <th style="width: 30%;">NOMBRE COMPLETO</th>
                                        <th style="width: 10%;">RUT O PASAPORTE</th>
                                        <th style="width: 15%;">NUMERO DE CONTACTO O CORREO ELECTRONICO</th>
                                        <th style="width: 10%;">REGISTRO DE TEMPERATURA</th>
                                        <th style="width: 10%;">¿TIENE ALGÚN SÍNTOMA RELACIONADO AL COVID-19? (SI/NO)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let detail of visit.details; let i = index" >
                                        <td >{{ i + 1 }}</td>
                                        <td >{{ detail.nametourist }}</td>
                                        <td >{{ detail.ruttourist }}</td>
                                        <td >{{ detail.contact }}</td>
                                        <td >{{ detail.temperature }}</td>
                                        <td ><label class="label label-danger" *ngIf="detail.covid">Si</label>
                                            <label class="label label-success" *ngIf="!detail.covid">No</label></td>

                                    </tr>

                                  
                                </tbody>
                            </table>
                        </div>

                        <div id="observation">
                            <p>* TEMPERATURAS IGUAL O SUPERIOR A 37,8 °C NO PODRÁ INGRESAR A LA EMPRESA Y SE RECOMENDARA ASISTIR AL CENTRO DE SALUD.</p>
                            <p>* SÍNTOMAS RELACIONADOS AL COVID-19: FIEBRE, ESTO ES, PRESENTAR UNA TEMPERATURA CORPORAL DE 37,8ºC O MÁS, TOS, DIFICULTAD RESPIRATORIA, DOLOR TORÁCICO, DOLOR DE GARGANTA, DOLOR MUSCULAR, ESCALOFRÍOS, DOLOR DE CABEZA, DIARREA, FALTA DE OLFATO Y FALTA DE GUSTO.</p>

                        </div>
                      
                        <div id="notices" style="text-align: center; padding-top: 60px;">
                           

                            <div>----------------------------------------------------------------------------</div>
                            <div class="notice">NOMBRE Y FIRMA DEL RESPONSABLE DE GRUPO</div>
                        </div>
                    </main>

                </div>
            </div>

        </div>
    </div>
</div>
