import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import { global } from './global';
import { map, delay } from 'rxjs/operators';


@Injectable()
export class BusinessService {
    public url: string;
    constructor(
        public _http: HttpClient
    ){
        this.url = global.url;
    }

    register(token,business): Observable<any>{

        const json = JSON.stringify(business);
        const params = 'json='+json;

        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.post(this.url+'business', params, { headers: headers });
    }

    index(token): Observable<any>{

        const params = '';

        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.get(this.url+'business',  { headers: headers });
    }
    show(token, id): Observable<any>{
        const params = '';

        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.get(this.url+'business/'+id ,  { headers: headers });

    }
    showmaster(token): Observable<any>{
        const params = '';

        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.get(this.url+'showmaster' ,  { headers: headers });

    }

    update(token,business): Observable<any>{

        const json = JSON.stringify(business);
        const params = 'json='+json;

        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.put(this.url+'business/'+business.id, params, { headers: headers });
    }

    delete(token, id): Observable<any>{
        const params = '';

        console.log(this.url+'business/'+id);
        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.delete(this.url+'business/'+id ,  { headers: headers });

    }

    changeactive(token, id): Observable<any>{
        const params = '';

        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.get(this.url+'business-changeactive/'+id ,  { headers: headers });

    }
    search(token,search = ''): Observable<any>{

        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.get(this.url+'business-search/'+search, { headers: headers });
    }

    postFile(token, FileUploader: File): Observable<any> {
        const endpoint = this.url+'business/upload';
        const formData: FormData = new FormData();
        formData.append('fileKey', FileUploader, FileUploader.name);
        const headers = new HttpHeaders().set('Authorization',token);
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this._http.post(endpoint, formData, { headers: headers });
    }

    report(token, search ): Observable<any>{

        const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Authorization',token);
        return this._http.get(this.url+'reportstatus/' + search, { headers: headers });
    }


}