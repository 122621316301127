import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavService, Menu } from '../../services/nav.service';
import { UserService } from 'src/app/services/user.service';
import { BusinessService } from 'src/app/services/business.service';
import { global } from 'src/app/services/global';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [UserService,BusinessService]
})
export class SidebarComponent {

  public menuItems: Menu[];
  public url: any;
  public fileurl: any;
  public identity: any;
  public profile: any;
  public logo: string;
  public token: string;
  public pathurl: any;

  constructor(private router: Router, public navServices: NavService, public UserService: UserService, public _businessService: BusinessService)
  {
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.url)
              this.setNavActive(items)
            if (!items.children) return false
            items.children.filter(subItems => {
              if (subItems.path === event.url)
                this.setNavActive(subItems)
              if (!subItems.children) return false
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url)
                  this.setNavActive(subSubItems)
              })
            })
          })
        }
      })
    });
    this.pathurl = global.url;
    this.identity = UserService.getIdentity();
    this.token = UserService.getToken();
    this.profiles();
    this.showlogo();
  }


  showlogo(){
    
    var businessid = this.identity.businessid;
   
    console.log(businessid, 'businessid');

    this._businessService.show(this.token,businessid).subscribe(
      response=>{
        // console.log(response);
        console.log(response.business);

        if(response.status === 'success'){

          this.logo = response.business.logo;
          
        
        }
      },
      error=>{
       
      });
  }

  profiles(){
    if(this.identity.profileid === 1){
      this.profile = 'CLIENTE';

    }else if(this.identity.profileid === 2){
      this.profile = 'CONTROL';
    }else if(this.identity.profileid === 3){
      this.profile = 'ADMINISTRACIÓN';
    }else{
      this.profile = 'FACTURADOR';
    }

  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }

}
