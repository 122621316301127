import { Injectable, HostListener,OnInit } from '@angular/core';

import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';




// Menu
export  interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root',

})

export class NavService implements OnInit{

	public screenWidth: any
	public collapseSidebar: boolean = false
	public fullScreen = false;
	public user: User;
	public MENUITEMS: Menu[];
	public items: any;
	constructor(public _userService: UserService) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
		this.MENUITEMS = [];
		this.user = _userService.getIdentity();
		

	}

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	ngOnInit(): void {
		this.MENUITEMS = [];
	}
  

	menu( profileid ){
		
		if(profileid === 1){
			this.MENUITEMS = [
				{
					title: 'Administración', icon: 'inbox', type: 'sub', badgeType: '', active: false, children:
						[
														
							{
								path: '/ticket/index', title: 'Ticket', type: 'link'
							}
		
				
					
						],
						
				},
				{ path: '/user/index',icon: 'user', title: 'Usuario', type: 'link' },
				{
					title: 'Visitas', icon: 'chevrons-left', type: 'sub', badgeType: '', active: false, children:[
						{ path: '/visit/index', title: 'Ver visitas', type: 'link', bookmark: true },
						{ path: '/visit/register', title: 'Nueva visita', type: 'link', bookmark: true },
						
					],
				},
				{
					title: 'Compras', icon: 'chevrons-right', type: 'sub', badgeType: '', active: false, children:[
						{ path: '/document/index', title: 'Ver compras', type: 'link', bookmark: true },
					
					],
				},
			
		
			];
		

		}

		if(profileid === 2){
			this.MENUITEMS = [
			
				{
					title: 'Visitas', icon: 'chevrons-left', type: 'sub', badgeType: '', active: false, children:[
						{ path: '/visit/index', title: 'Ver visitas', type: 'link', bookmark: true }
						
					],
				},
				{ path: '/user/index',icon: 'user', title: 'Usuario', type: 'link' }
				
			
		
			];
		

		}


		if(profileid === 3){
			this.MENUITEMS = [
				{
					title: 'Administración', icon: 'inbox', type: 'sub', badgeType: '', active: false, children:
						[
						
					
							{
								path: '/business/index', title: 'Agencias', type: 'link'
							},
							
				
							{
								title: 'Administrador', type: 'sub', active: false, children: [
									{ path: '/user/index', title: 'Usuarios', type: 'link' },
									{ path: '/profile/index', title: 'Perfiles', type: 'link' },
								],
								
							},

							
						],
						
				},
				{
					title: 'Visitas', icon: 'chevrons-left', type: 'sub', badgeType: '', active: false, children:[
						{ path: '/visit/index', title: 'Ver visitas', type: 'link', bookmark: true }
						
					],
				},
			
				{
					title: 'Compras', icon: 'chevrons-right', type: 'sub', badgeType: '', active: false, children:[
						{ path: '/document/register', title: 'Nueva compra', type: 'link', bookmark: true },
						{ path: '/document/index', title: 'Ver compras', type: 'link', bookmark: true },

					],
				},
				{
					title: 'Reportes', icon: 'chevrons-right', type: 'sub', badgeType: '', active: false, children:[
						{ path: '/report/index', title: 'Estado Agencias', type: 'link', bookmark: true }
					

					],
				},
				{
					title: 'Papelera', icon: 'trash', type: 'sub', badgeType: '', active: false, children:[
						{ path: '/visit/index/trash', title: 'Papelera de visitas', type: 'link', bookmark: true }
					

					],
				},
			
		
			];
		

		}

		if(profileid === 4){
			this.MENUITEMS = [
				
				{
					title: 'Compras', icon: 'chevrons-right', type: 'sub', badgeType: '', active: false, children:[
						{ path: '/document/register', title: 'Nueva compra', type: 'link', bookmark: true },
						{ path: '/document/index', title: 'Ver compras', type: 'link', bookmark: true },

					],
				},
				{
					title: 'Reportes', icon: 'chevrons-right', type: 'sub', badgeType: '', active: false, children:[
						{ path: '/report/index', title: 'Estado Agencias', type: 'link', bookmark: true }
					

					],
				},
			
		
			];
		

		}

		
	
		return this.items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
		
	}

	


}
