<!-- footer start-->
<div class="container-fluid">
    <div class="row">
        <div class="col-md-6 footer-copyright">
            <p class="mb-0">Desarrollado por TellPerú 2020, todos los derecho reservados.</p>
        </div>
        <div class="col-md-6">
            <!-- <p class="pull-right mb-0">Hand crafted & made with<i class="fa fa-heart"></i></p> -->
        </div>
    </div>
</div>