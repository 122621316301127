
import { Component, OnInit, Output, EventEmitter, Inject  } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { DOCUMENT } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';
import { BusinessService } from 'src/app/services/business.service';
import { global } from 'src/app/services/global';


var body = document.getElementsByTagName("body")[0];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [BusinessService]
 
})
export class HeaderComponent implements OnInit {

  public menuItems: Menu[];
  public items: Menu[];
  public openNav: boolean = false;
  public right_sidebar: boolean = false;
  public text: string;
  public elem;
  public user: User;
  public logo: string;
  public token: string;
  public pathurl: any;


  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(public navServices: NavService,@Inject(DOCUMENT) private document: any, public _user: UserService, public _businessService: BusinessService) { 
      this.user = _user.getIdentity();
      this.token = _user.getToken();
      this.pathurl = global.url;
      this.menu();
      this.showlogo();
   }

  ngOnInit() {
    this.elem = document.documentElement;
    
  }
  showlogo(){
    
    var businessid = this.user.businessid;
   
    console.log(businessid, 'businessid');

    this._businessService.show(this.token,businessid).subscribe(
      response=>{
        // console.log(response);
        console.log(response.business);

        if(response.status === 'success'){

          this.logo = response.business.logo;

        }
      },
      error=>{
       
      });
  }

  menu(){
    
    this.navServices.menu(this.user.profileid).subscribe(menuItems => {
      this.items = menuItems
      console.log(menuItems);
    });
   
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  collapseSidebar() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

}
