import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentLayoutComponent } from './shared/components/layout/content-layout/content-layout.component';
import { content } from './shared/routes/content-routes';
import { LoginComponent } from './components/login/login.component';
import { VisitprintComponent } from './components/visitprint/visitprint.component';
import { IdentityGuard } from './services/identity.guard';
// import { WarehouselistComponent } from './components/warehouseconfig/warehouselist/warehouselist.component';
// import { CategoryregisterComponent } from './components/category/categoryregister/categoryregister.component';

// import { ReactiveFormsModule } from '@angular/forms';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContentLayoutComponent,
    children: content
  },
  {
    path: 'logout/:sure', component: LoginComponent
  },
  // {
  //    path: '**',
  //    redirectTo: '/sample/sample-component'
  // },
  {
    path: 'login',
    component: LoginComponent
  },

  {
    path: 'visitprint/:id',
    component: VisitprintComponent
  },
  // {
  //   path: 'warehouseconfig',
  //   component: WarehouselistComponent
  // }


];

@NgModule({
  imports: [RouterModule.forRoot(routes),
    // ReactiveFormsModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
